<template>
  <div class="main-frame">
    <div v-if="!$route.path.includes('/master')">
      <div class="category-title" v-if="$route.path.includes('/myPage')">
        마이페이지 <img class="title-icon" src="../assets/images/icon/icon_gray_right.png"> 내 게시물 관리
      </div>
      <div class="category-title" v-else>
        홈 <img class="title-icon" src="../assets/images/icon/icon_gray_right.png"> {{ categoryTitle }}
        <img class="title-icon" src="../assets/images/icon/icon_gray_right.png">{{ itemData.subCategory }}
      </div>
    </div>
    <div class="item-box">
      <div class="item-img">
        <img :src=itemData.thumbnail>
      </div>
      <div class="item-data">
        <div>
          <p class="sub-category">{{ itemData.subCategory }}</p>
          <h3 class="title">{{ itemData.title }}</h3>
          <p class="seller">판매자 <strong>{{ itemData.seller }}</strong></p>
        </div>
        <div v-if="isEditer" class="sub-category">
          95%의 구매자가 이 제품에 만족하고있습니다.
        </div>
        <div>
          <div class="price">
            <img src="../assets/images/icon/icon_recipt.png">
            {{ itemData.price }}원
          </div>
          <div class="data-container">
            <div class="data-box" :class="{myItem:isEditer}">
              <div class="text" v-if="!isEditer">
                <p>구매 만족도 <br/><span>95%</span></p>
              </div>
              <div class="line" v-if="!isEditer"/>
              <div class="text">
                <p>업데이트<br/><span>{{ itemData.lastDate }}</span></p>
              </div>
            </div>
            <button v-if="isEditer" class="edit custom-btn" @click="editItem">수정하기</button>
            <button v-if="isEditer" class="del custom-btn" @click="delItem">삭제하기</button>
            <button v-else class="custom-btn" @click="purchase">구매하기</button>
          </div>
        </div>
      </div>
    </div>
    <div class="data-info">
      <div class="data-title"> 제품 상세정보</div>
      <p class="data-text" v-html="itemData.itemDetail"/>
      <div v-if="itemData.itemDetails !== ''" class="data-detail" @click="dropdown = !dropdown">상세정보 더보기</div>
      <slide-up-down :active="dropdown" :duration="500">
        <div class="dropdown-text" v-html="itemData.itemDetails"/>
      </slide-up-down>
    </div>
    <Progress :isProgress="isProgress"></Progress>
  </div>
</template>

<script>
import {firestore} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import SlideUpDown from "vue-slide-up-down";
import Progress from "@/components/Progress.vue";

export default {
  name: "itemDetail",
  components: {
    Progress,
    SlideUpDown,
  },
  data() {
    return {
      pageType: this.$route.params.type,
      uid: this.$route.params.uid,
      isEditer: false,
      isProgress: false,
      isMyItem: false,
      categoryTitle: '',
      dropdown: false,
      itemData: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const self = this;
      switch (self.pageType) {
        case 'plugin':
          self.categoryTitle = '플러그인/라이브러리';
          break;
        case 'program':
          self.categoryTitle = '프로그램 패키지';
          break;
        case 'design':
          self.categoryTitle = '디자인';
          break;
      }
      if (!this.$route.path.includes('/category')) {
        this.isEditer = true
      }
      self.getItem()
    },
    getItem() {
      const self = this;
      firestore.collection('item')
          .doc(self.uid)
          .get()
          .then((snapshot) => {
            self.itemData = snapshot.data()
            const date = new Date(snapshot.data().lastDate.seconds * 1000);
            self.itemData.lastDate = getDate(date)
            self.itemData.price = self.itemData.price.toLocaleString()
            self.itemData['uid'] = snapshot.id
            if (self.itemData.sellerInfo.uid === self.$store.state.uid) self.isMyItem = true
          })
    },
    delItem() {
      const self = this;
      self.$swal.fire({
        title: '게시물을 삭제하시겠습니까?',
        text: "확인을 누르시면 게시물이 삭제됩니다",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          self.isProgress = true
          await firestore.collection('item')
              .doc(self.itemData.uid)
              .delete()
              .then(() => {
                self.isProgress = false
                self.$swal.fire('삭제완료!', '게시물이 삭제 되었습니다.', '성공')
                    .then(async () => {
                      self.$router.go(-1)
                    })
              })
        }
      })
    },
    editItem() {
      const self = this;
      if (this.$route.path.includes('/myPage'))
        self.$router.push({name: 'EditItem', params: {itid: self.itemData.uid}})
      else
        self.$router.push({name: 'EditPost', params: {itid: self.itemData.uid}})
    },
    purchase() {
      const self = this;
      self.$router.push({name: 'ItemPurchase'})
    }
  }
}
</script>

<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .category-title {
  text-align: start;
  color: #71717A;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}


.main-frame .category-title .title-icon {
  width: 18px;
  height: 18px;
}

.main-frame .item-box {
  display: flex;
  margin-bottom: 60px;
}

.main-frame .item-box .item-img {
  width: 586px;
  height: 336px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #FFF;
  margin-right: 20px;
  overflow: hidden;
  border: 1px solid #E4E4E7;
}

.main-frame .item-box .item-img img {
  width: 100%;
  height: 100%;
}

.main-frame .item-box .item-data {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 586px;
}

.main-frame .item-box .item-data .sub-category {
  color: #71717A;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
}

.main-frame .item-box .item-data h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 10px;
}

.item-box .item-data .seller {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
}

.item-box .item-data .seller strong {
  font-weight: 700;
}

.item-box .item-data .price {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.item-box .item-data .price img {
  width: 30px;
  height: 30px;
  margin-right: 6px;
}

.item-box .item-data .data-container {
  display: flex;
}

.item-box .item-data .data-container .data-box {
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  width: 416px;
  height: 96px;
  padding: 16px;
  display: flex;
  margin-right: 10px;
}

.item-box .item-data .data-container .data-box .line {
  height: 100%;
  width: 1px;
  background: #E4E4E7;
}

.item-box .item-data .data-container .data-box .text {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #71717A;
  line-height: 29px;

}

.item-box .item-data .data-container .data-box .text span {
  font-size: 24px;
  line-height: 35px;
}

.item-box .item-data .data-container .myItem {
  width: 50%;
}

.item-box .item-data .data-container .custom-btn {
  width: 160px;
  height: 96px;
  font-size: 20px;
  line-height: normal;
  letter-spacing: -0.4px;
}

.edit {
  margin-right: 10px;
}

.del {
  background: #EF4444;
}

.main-frame .data-info .data-title {
  height: 61px;
  padding: 16px;
  border-radius: 20px;
  background: #FBF4EB;
  color: #71717A;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.main-frame .data-info .data-text {
  color: #71717A;
  text-align: center;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin: 20px auto;
  width: 550px;
  word-break: keep-all;
  white-space: pre-line;
}

.main-frame .data-info .data-detail {
  color: #FF8A00;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.dropdown-text {
  color: #71717A;
  text-align: center;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 10px;
}
</style>